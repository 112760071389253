import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { BoxBorder } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Investors Outside the U.S.`}</h1>
    <Breadcrumbs text="Help" to="/help/" mdxType="Breadcrumbs" />
    <br />
    <BoxBorder mdxType="BoxBorder">
      <p>{`This article is work in progress. We will certainly add to it and most likely also make some corrections. Stay tuned!`}</p>
    </BoxBorder>
    <p><em parentName="p">{`TuringTrader.com`}</em>{` first and foremost aims at investors residing in the US. Investors in other locations need to verify the following:`}</p>
    <ul>
      <li parentName="ul">{`Are the assets traded by the strategies available to investors in my country or situation?`}</li>
      <li parentName="ul">{`Do fluctuations in exchange rates between my country's currency and US$ pose a risk to my investment objective?`}</li>
    </ul>
    <h2>{`Stock-based Portfolios`}</h2>
    <p>{`Stocks are available for trading around the world. Therefore, there shouldn't be any issues implementing any of our stock-trading portfolios in countries other than the USA. In particular, we have the following portfolios:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/portfolios/tt-stocks-on-the-loose/"
        }}>{`Stocks on the Loose`}</a>{` or `}<a parentName="li" {...{
          "href": "/portfolios/tt-stocks-on-a-stroll/"
        }}>{`Stocks on a Stroll`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/portfolios/tt-quick-change/"
        }}>{`Quick-Change`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/portfolios/clenow-stocks-on-the-move/"
        }}>{`Clenow's Stocks on the Move`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/portfolios/bensdorp-weekly-rotation/"
        }}>{`Bensdorp's Weekly Rotation`}</a></li>
    </ul>
    <p>{`Even though these portfolios primarily trade US stocks, they might still use some ETFs as risk-off assets. These are mostly bonds, which can be substituted with similar products available in your country. If you can't find suitable substitutes even cash should do.`}</p>
    <h2>{`ETF-based Portfolios`}</h2>
    <p>{`Unfortunately, many of the ETFs we are using on TuringTrader.com are not registered in Europe, requiring a slightly more involved process to track these ETF-based portfolios. However, there are multiple ways to remedy this situation:`}</p>
    <ul>
      <li parentName="ul">{`Accredited investors often have access to the original U.S. ETFs. Check with your brokerage how to gain this status.`}</li>
      <li parentName="ul">{`For many US ETFs there exist European ETFs tracking the exact same indices. While this extra step is a nuisance, the resulting 'native' European portfolio is likely worth the effort.`}</li>
      <li parentName="ul">{`Oftentimes, US ETFs can be traded through CFDs instead. However, these products are typically more expensive than ETFs, plus they add some credit risk. Consequently, this this solution is less attractive than finding suitable ETF substitutes.`}</li>
      <li parentName="ul">{`And finally, some US brokerages allow accounts to be opened from Europe, see this `}<a parentName="li" {...{
          "href": "https://europoor.com/"
        }}>{`article on Europoor.com`}</a>{`. It is unclear how long this loophole will exist.`}</li>
    </ul>
    <h2>{`Outlook`}</h2>
    <p>{`In the longer term, we are planning to add features to make it easier for European investors to use our service. We are planning to add features to (a) substitute ETFs in our asset allocation, and (b) convert currencies. However, these features are not available just yet.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      